exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amigos-do-bairro-js": () => import("./../../../src/pages/amigos-do-bairro.js" /* webpackChunkName: "component---src-pages-amigos-do-bairro-js" */),
  "component---src-pages-carreiras-js": () => import("./../../../src/pages/carreiras.js" /* webpackChunkName: "component---src-pages-carreiras-js" */),
  "component---src-pages-clubdia-cupons-js": () => import("./../../../src/pages/clubdia/cupons.js" /* webpackChunkName: "component---src-pages-clubdia-cupons-js" */),
  "component---src-pages-clubdia-js": () => import("./../../../src/pages/clubdia.js" /* webpackChunkName: "component---src-pages-clubdia-js" */),
  "component---src-pages-clubdia-politica-de-privacidade-js": () => import("./../../../src/pages/clubdia/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-clubdia-politica-de-privacidade-js" */),
  "component---src-pages-clubdia-politicas-js": () => import("./../../../src/pages/clubdia/politicas.js" /* webpackChunkName: "component---src-pages-clubdia-politicas-js" */),
  "component---src-pages-clubdia-termos-js": () => import("./../../../src/pages/clubdia/termos.js" /* webpackChunkName: "component---src-pages-clubdia-termos-js" */),
  "component---src-pages-codigo-de-etica-js": () => import("./../../../src/pages/codigo-de-etica.js" /* webpackChunkName: "component---src-pages-codigo-de-etica-js" */),
  "component---src-pages-comer-melhor-cada-dia-js": () => import("./../../../src/pages/comer-melhor-cada-dia.js" /* webpackChunkName: "component---src-pages-comer-melhor-cada-dia-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-imprensa-js": () => import("./../../../src/pages/imprensa.js" /* webpackChunkName: "component---src-pages-imprensa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-institucional-codigo-de-etica-js": () => import("./../../../src/pages/institucional/codigo-de-etica.js" /* webpackChunkName: "component---src-pages-institucional-codigo-de-etica-js" */),
  "component---src-pages-institucional-recuperacaojudicial-js": () => import("./../../../src/pages/institucional/recuperacaojudicial.js" /* webpackChunkName: "component---src-pages-institucional-recuperacaojudicial-js" */),
  "component---src-pages-institucional-relatorio-transparencia-salarial-js": () => import("./../../../src/pages/institucional/relatorio-transparencia-salarial.js" /* webpackChunkName: "component---src-pages-institucional-relatorio-transparencia-salarial-js" */),
  "component---src-pages-institucional-sobre-dia-js": () => import("./../../../src/pages/institucional/sobre-dia.js" /* webpackChunkName: "component---src-pages-institucional-sobre-dia-js" */),
  "component---src-pages-lojas-js": () => import("./../../../src/pages/lojas.js" /* webpackChunkName: "component---src-pages-lojas-js" */),
  "component---src-pages-lojas-participantes-js": () => import("./../../../src/pages/lojas-participantes.js" /* webpackChunkName: "component---src-pages-lojas-participantes-js" */),
  "component---src-pages-ofertas-js": () => import("./../../../src/pages/ofertas.js" /* webpackChunkName: "component---src-pages-ofertas-js" */),
  "component---src-pages-politicas-compromissos-associacoes-js": () => import("./../../../src/pages/politicas-compromissos-associacoes.js" /* webpackChunkName: "component---src-pages-politicas-compromissos-associacoes-js" */),
  "component---src-pages-politicas-corporativas-protecao-de-dados-js": () => import("./../../../src/pages/politicas-corporativas-protecao-de-dados.js" /* webpackChunkName: "component---src-pages-politicas-corporativas-protecao-de-dados-js" */),
  "component---src-pages-privacidade-js": () => import("./../../../src/pages/privacidade.js" /* webpackChunkName: "component---src-pages-privacidade-js" */),
  "component---src-pages-produtos-js": () => import("./../../../src/pages/produtos.js" /* webpackChunkName: "component---src-pages-produtos-js" */),
  "component---src-pages-receitas-busca-js": () => import("./../../../src/pages/receitas/busca.js" /* webpackChunkName: "component---src-pages-receitas-busca-js" */),
  "component---src-pages-receitas-tags-js": () => import("./../../../src/pages/receitas/tags.js" /* webpackChunkName: "component---src-pages-receitas-tags-js" */),
  "component---src-pages-responsabilidade-social-js": () => import("./../../../src/pages/responsabilidade-social.js" /* webpackChunkName: "component---src-pages-responsabilidade-social-js" */),
  "component---src-templates-ofertas-app-js": () => import("./../../../src/templates/ofertas-app.js" /* webpackChunkName: "component---src-templates-ofertas-app-js" */),
  "component---src-templates-ofertas-site-js": () => import("./../../../src/templates/ofertas-site.js" /* webpackChunkName: "component---src-templates-ofertas-site-js" */),
  "component---src-templates-receitas-categorias-js": () => import("./../../../src/templates/receitas-categorias.js" /* webpackChunkName: "component---src-templates-receitas-categorias-js" */),
  "component---src-templates-receitas-tags-js": () => import("./../../../src/templates/receitas-tags.js" /* webpackChunkName: "component---src-templates-receitas-tags-js" */),
  "component---src-templates-receitas-template-js": () => import("./../../../src/templates/receitas-template.js" /* webpackChunkName: "component---src-templates-receitas-template-js" */),
  "component---src-templates-semanadacarnesuina-js": () => import("./../../../src/templates/semanadacarnesuina.js" /* webpackChunkName: "component---src-templates-semanadacarnesuina-js" */),
  "component---src-templates-template-lojas-sp-js": () => import("./../../../src/templates/template-lojas-sp.js" /* webpackChunkName: "component---src-templates-template-lojas-sp-js" */)
}

